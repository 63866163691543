/* eslint-disable import/extensions, import/no-unresolved */
import React from 'react';
import { FONT_SIZE } from '@latitude/core/utils/constants';
import { StaticImage } from 'gatsby-plugin-image';

import {
  StyledHeroBranded,
  ImageBox,
  LeaderDetailsWrapper,
  DetailsContainer,
  StyledText,
  LeadershipPageLayout
} from '../../../components/Leadership';

export default props => {
  const title = 'Paul Varro';
  const content =
    'Chief Financial Officer and Executive General Manager Finance.';

  return (
    <LeadershipPageLayout title={title} {...props}>
      <StyledHeroBranded
        title={title}
        text={content}
        breadcrumbs={[
          {
            name: 'About us',
            href: '/about-us/'
          },
          {
            name: 'Our company',
            href: '/about-us/our-company/'
          },
          {
            name: 'Paul Varro'
          }
        ]}
        imageContent={
          <ImageBox>
            <StaticImage
              alt={title}
              src="../images/paul-varro.webp"
              width={450}
              quality={100}
              placeholder="dominantColor"
            />
          </ImageBox>
        }
      />
      <LeaderDetailsWrapper>
        <DetailsContainer display="flex">
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Prior to January 2022 he was Latitude's Chief Commercial Officer,
            with end-to end responsibility for all products including credit
            cards and retail finance, personal lending and insurance products,
            along with product marketing and credit risk.
            <br />
            <br />
            Paul's career includes experience in financial services across
            Australia, United Kingdom, Ireland, and United States. A chartered
            accountant, he began his career with Deloitte and holds a Bachelor
            of Business degree in Accounting and Business Law.
          </StyledText>
          <StyledText fontSize={FONT_SIZE.LARGE}>
            Paul has held a range of senior executive and board positions since
            joining GE/Latitude in 2008, including CFO Insurance, CFO Consumer
            Finance (GE), Group Treasurer, Executive General Manager, Product,
            and Executive General Manager, Pay and Insurance.
          </StyledText>
        </DetailsContainer>
      </LeaderDetailsWrapper>
    </LeadershipPageLayout>
  );
};
